// Layout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { AdminProvider } from '../../AdminContext'; // עדכון הנתיב בהתאם
import "./Layout.css"; // נניח שהעיצוב נשמר בקובץ CSS נפרד
//import '../../components/pages/NoScroll.css'; 

const Layout: React.FC = () => {
  return (
    <AdminProvider>
      <div className="app-container">
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </AdminProvider>
  );
};

export default Layout;
