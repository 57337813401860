const terms_approval:{ [key: string]: string }  = {
    

    billagan_terms_approval:`תקנון אתר elitzurLod.billagan לגביית תשלומי הורים בצהרונים
    <br><br>
    מבוא – 
    <br><br>
    ברוכים הבאים לאתר https://afternoon.billagan.co.il (להלן: "האתר") המופעל על ידי ח.צ טכנולוגיות בע"מ, ח.פ 516540457, מכתובת הבע"שט 6 לוד (להלן: "הנהלת האתר" ו/או "החברה").
    <br><br>
    כל שימוש באתר מהווה הסכמה לתקנון זה, על התנאים וההגבלות הכלולים בו.
    <br><br>
    התקנון עלול להשתנות מעת לעת, ועל המשתמש להתעדכן בתקנון בכל כניסה לאתר. גלישה באתר ו/או שימוש בו מכל סוג, כמוהם כהסכמה לתנאי התקנון והתחייבות לפעול על פיהם. מובהר כי התקנון מהווה הסכם משפטית מחייבת לכל דבר ועניין ומחייב את המשתמש על כל שימושיו באתר.
    <br>
    האמור בתקנון זה בלשון זכר הוא לשם הנוחות בלבד והתקנון מתייחס לבני שני המינים באופן שווה.
    השירותים באמצעות האתר כפופים לתנאים המפורטים בתקנון זה.
    עצם הזמנת שירותים באתר תהווה הצהרה מצד הלקוח כי קרא את הוראות תקנון זה, הבין אותן והסכים להן. התקנון מהווה חוזה מחייב בין המשתמש לבין הנהלת האתר.
    <br><br>
    השירותים – 
    <br>
    האתר מאפשר שירות גביית תשלומי הורים לצהרונים
    ניתן לשלם עבור שירותי האתר באמצעות כרטיס אשראי בכפוף לאישור הנהלת האתר.
    מחירי השירותים הינם כפי שנקבעו ע"י הרשות המקומית בהתאם לחוזר מנכ"ל משרד החינוך בענין תשלומי הורים.
    קבלה בגין התשלום תשלח באופן אוטמטי בסמוך לאישור התשלום שבוצע באתר. בכל בעיה או בקשה בנושא אסמכתאות התשלום, ניתן לפנות לשירות הלקוחות של חברת ח.צ טכנולוגיות בע"מ במייל office@htz-tech.co.il  או בטלפון: 0585511320
    הנהלת האתר שומרת לעצמה את הזכות להפסיק את שירותיה בכל עת.
    הזכאות לשימוש באתר הינה לכל אזרח אשר מלאו לו 18 שנים. הזמנה תתאפשר למי שברשותו חשבון בנק פעיל ו/או בעל כרטיס אשראי ישראלי תקף והינו בעל תיבת דואר אלקטרוני אשר כתובתה תינתן בעת ביצוע התשלום באתר. קטין המבצע פעולות ייחשב כקטין אשר קיבל את רשות הוריו/אפוטרופוסיו לביצוע התשלום באתר.
    <br><br>
    ביטולים ושינוי סוגי השירותים – 
    <br>
    מדיניות ביטול עסקה והחזרת התשלום, בהתאם למדיניות הרשות המקומית תתבצע במידה והילד נשוא התשלום עזב את מוסד הלימודים עבורו שילם.
    ההחזר יינתן החל מהחודש העוקב לתאריך העזיבה ומותנה בהודעה על העזיבה לשירות הלקוחות של חברת ח.צ טכנולוגיות בע"מ במייל office@htz-tech.co.il  או בטלפון לשירות הלקוחות: 0585511320
    <br><br>
    זמינות ומיקום אזורי – 
    <br>
    בכל שאלה או בירור לגבי השירותים ניתן לחייג אל שירות הלקוחות של ח.צ טכנולוגיות בע"מ בטלפון 0585511320
    <br><br>
    אחריות ושירות – 
    <br>
    השירותים יינתנו בצהרונים בהתאם לדרישות הרשות המקומית ועל אחריותה.
    אורך השירות הינו ע"פ מועד הזמן אשר נקבע מראש ע"י הרשות המקומית.
    הנהלת האתר אינה אחראית על נזקים פיזיים או כספיים או כל נזק אחר שנגרמו במהלך השירות .
    הנהלת האתר מסירה מעליה כל אחריות מהתכנים הנכתבים באתר או מהשימוש בהם והשלכותיהם.
    <br><br>
    אבטחת מידע ופרטיות – 
    <br>
    הנהלת האתר, עושה כל שביכולתה בכדי לשמור על סודיות הלקוח ומתחייבים לא להעביר את פרטי האשראי לצד ג' אלא בהוראת בית דין. דף התשלום עומד בתקן אבטחה PCI – DSS
    באתר לא נאסף מידע על הלקוח למעט אמצעי התשלום איתו משולם השירות.
    תוכן השירות וחשיפת מידע אישי במהלך השירות, לעולם לא ייחשפו לאדם אחר או כל גורם ללא אישור בכתב מהלקוח. הנהלת האתר מחויבת לפרטיות שלך ופרטיות זו הינה בראש סדר העדיפויות שלנו.
    החברה מתחייבת שלא לעשות שימוש במידע המסופק לה ע"י הקונים אלא על מנת לאפשר את הרכישה באתר התשלום ובהתאם לכל דין.
    באמצעות לחיצה על הכפתור אני מאשר בחתימתי את הטופס ואת תנאיו.
    האתר מאובטח באמצעות שימוש באמצעי אבטחה מתקדמים אשר מטרתם להבטיח שימוש תקין וגלישה בטוחה באתר וכן על מנת לשמור על פרטיות המשתמשים. כל המשתמש באתר ובשירותיו מתחייב כי לא יעשה כל פעילות שיש בה כדי לשבש את פעילות האתר, גניבת מידע על משתמשים ופריצה של מנגנוני האבטחה של האתר.
    במקרה של שימוש לרעה, הנהלת האתר תפעל נגד כל פעילות בכל דרך חוקית אשר תעמוד לרשותה לרבות חסימת המשתמש מגישה לאתר ונקיטת הליכים משפטיים נגד המשתמש באם יפעל כאמור.
    <br><br>
    קניין רוחני – 
    <br>
    כל זכויות הקניין הרוחני באתר זה הכוללים זכויות יוצרים, זכויות הפצה, סודות מסחריים, סימני המסחר וכל הקניין הרוחני מכל סוג שהוא, הן בנוגע לעיצוב ועמוד האתר, הן באשר לתכנים המופיעים בו הינן רכושה הבלעדי של הנהלת האתר.
    אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מן האתר ו/או מהאתר, אלא אם ניתנה הסכמה לכך מראש ובכתב מטעם הנהלת האתר.
    <br>
    דין וסמכות שיפוט –
    <br><br>
    פרשנותו ואכיפתו של תקנון זה ו/או כל פעולה או סכסוך הנובע ממנו יעשו בהתאם לדין הישראלי בלבד ולבית המשפט המוסמך בלוד תהא מסורה סמכות השיפוט הבלעדית.
    <br><br>
    יצירת קשר – 
    <br>
    לכל שאלה ופנייה ניתן ליצור קשר עם הנהלת האתר בטלפון 0585511320 או במייל office@htz-tech.co.il   בשעות הפעילות של מוקד שירות הלקוחות א'-ה' בין השעות 9:00-15:00.
    `,


  };
  
  export default terms_approval;
  