import { createBrowserRouter, Navigate, Route } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import CreditcardHok from "./components/pages/CreditcardHok";
import ThankYouPage from "./components/pages/ThankYouPage";
import Layout from "./components/Layout/Layout";

// יבוא הקומפוננטה מהמיקום החדש


const router = createBrowserRouter([
  {
    path: 'thankYouPageRedirect',
    element: <ThankYouPage />,
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
     
      {
        path: 'creditcardHok',
        element: <CreditcardHok />,
      },
    ],
  },
]);

export default router;