import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import appConfig from '../../utils/appConfig';

const ThankYouPage: React.FC = () => {
  const location = useLocation();
  const [paymentData, setPaymentData] = useState({
    response: '',
    userId: '',
    sum: '',
    email: '',
    confirmationCode: ''
  });

  const [formData, setFormData] = useState({});

  // חילוץ נתונים מה-URL ומה-Session Storage
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPaymentData({
      response: params.get('response') || '',
      userId: params.get('userId') || '',
      sum: params.get('sum') || '',
      email: params.get('email') || '',
      confirmationCode: params.get('confirmationCode') || ''
    });
  }, [location.search]);

  useEffect(() => {
    const storedFormData = sessionStorage.getItem('formData');
    if (storedFormData) {
      const parsedData = JSON.parse(storedFormData);
      console.log('Parsed Form Data:', parsedData);
      setFormData(parsedData);
      sessionStorage.removeItem('formData'); // מחיקת הנתונים לאחר השימוש
    }
  }, []); // שים לב שהתלות ריקה כאן כי אנחנו לא צריכים להפעיל את זה שוב אלא אם הדף נטען מחדש

  // שליחת נתונים אם response === '000'
  useEffect(() => {
    if (paymentData.response === '000') {
      const dataToSend = {
        paymentData,
        formData
      };

      axios.post(appConfig.dataUrl, dataToSend)
        .then(response => {
          console.log('Data sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
    }
  }, [paymentData, formData]);

  console.log('Payment Data State:', paymentData);
  
  return (
    <Box sx={{ textAlign: 'center', marginTop: 4 }}>
      {paymentData.response === '000' ? (
        <>
          <Typography variant="h4" sx={{ color: '#7b1213', fontWeight: 'bold' }}>
            התשלום עבר בהצלחה, תודה
          </Typography>
          <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
            מספר אישור {paymentData.confirmationCode}
          </Typography>
          <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
            אישור תשלום ישלח במייל
          </Typography>
        </>
      ) : (
        <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
          ארעה שגיאה בתהליך התשלום, אנא נסו שוב, באם התקלה חוזרת פנו אלינו בטופס צור קשר
        </Typography>
      )}
    </Box>
  );
};

export default ThankYouPage;
